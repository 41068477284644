import React from 'react';
import Bottom from "./parts/Bottom";

import Top from "./parts/Top";

import Logo from "./parts/Logo";
import {getItem, getSurveyData, setStep} from "./utils/lib";

import {Steps} from "./Survey";
import {Trans, useTranslation} from "react-i18next";
import LinkButton from "./parts/LinkButton";
import Button from "./parts/Button";
import Stars from "./parts/Stars";

import {ReactComponent as IconCheck} from "./assets/img/icon/check.svg";
import {ReactComponent as IconVoice} from "./assets/img/icon/voice.svg";
import ReactAudioPlayer from "react-audio-player";

export default function Listen({step, toAdd, nextUrl, preload}) {

  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const resultList = getItem('survey_results');
  const g =
    +resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
    ]?.[0] || 0;

  const list = t('listen.list', {returnObjects: true});

  const object = list[g] || list[0];

  // const A17 = getExperiment('A17');
  const A17 = {value: 'B'};

  React.useLayoutEffect(() => {
    setStep(step, toAdd);

    document.head.insertAdjacentHTML('beforeend',
      `<style>
        .stop { display: inline-block; vertical-align: middle; width: 16px; height: 16px; background-color: currentColor; border-radius: 3px; }
        .btn .progress { position: absolute; left: 0; width: 100%; bottom: 0; }
        
        audio::-webkit-media-controls-current-time-display,
        audio::-webkit-media-controls-time-remaining-display { display: none; }
      </style>`
    );

    if (A17.value === 'C') {
      document.body.classList.add('light');

      return () => {
        document.body.classList.remove('light');
      };
    }
  }, []);

  return (
    <div className={'wrap fixed md'}>
      <Top>
        {/*        <div className={'row'} style={{gap: '1.55rem'}}>
          <LinkButton variant={'secondary'} to={'/loading'} className={'back'}>Back</LinkButton>
          <Logo/>
        </div>*/}

        <Logo path={A17.value === 'C' ? '/light' : ''}/>

        <Steps index={surveyList.length - 1}/>

        <div className={'w-100'} style={{position: 'relative'}}>
          <div style={{position: 'absolute', width: '100%', height: '55%', left: 0, bottom: 0, borderBottomLeftRadius: 'var(--radius)', borderBottomRightRadius: 'var(--radius)', background: 'linear-gradient(180deg, rgba(1, 3, 11, 0) 0%, rgba(1, 3, 11, 0.6) 63.5%, rgba(1, 3, 11, 0.8) 100%)'}}/>
          <div style={{position: 'absolute', zIndex: 1, bottom: 12, left: 12, right: 12}}>
            <div className={'row'} style={{gap: 0, justifyContent: 'space-between'}}>
              <div className={'text-start small'} style={{color: '#eee'}}>
                <strong>{object.name}</strong>
                <p className={'small-sm'} style={{marginTop: 4, fontSize: 10}}>{t('listen.location')}</p>
              </div>
              <Stars/>
              <div className={'small-sm'} style={{padding: '5px 9px', color: '#5DDEA7', backgroundColor: 'rgba(93, 222, 167, 0.2)', borderRadius: 'var(--radius-sm)'}}>
                <IconCheck className={'me-2'} fill={'currentColor'}/>
                {t('listen.verified')}
              </div>
            </div>
          </div>
          <img className={'w-100 mw-100 rounded-2 aspect-1'} src={require(`./assets/img/face/listen-${object.sex}.png`)} width={350} height={200} alt={''}/>
        </div>

        <p className={'text-start notice md'}>
          <Trans i18nKey="listen.tool" t={t}>
            Great to have a tool <b className={'l'}>to help me tackle my personal challenges on why I lack confidence</b> and have career, family, and relationship difficulties. I feel at ease after going through a month of the program.
          </Trans>
        </p>

        <div className={'row sm'} style={{minHeight: 48}}>
          <AudioButton type={'before'} sex={object.sex}/>
          <AudioButton type={'after'} sex={object.sex}/>
        </div>
      </Top>

      <Bottom preload={preload}>
        <LinkButton to={nextUrl}>{t('continue')}</LinkButton>
      </Bottom>
    </div>
  )
}

function AudioButton({type, sex}) {
  const {t} = useTranslation();
  const audioRef = React.useRef();

  const [state, setState] = React.useState({
    play: false,
    progress: 0,
  });

  window['state_' + type] = state;

  const handlePlay = () => {
    const audio = audioRef.current.audioEl.current;

    audio.addEventListener('timeupdate', (e) => {
      const antonim = type == 'before' ? 'after' : 'before';

      if (window['state_' + antonim].play)
        window['state_' + type].play = false;

      window['state_' + type].progress = audio.currentTime / (audio.duration / 100);
      setState({...window['state_' + type]});
    });
  };

  return (
    <Button variant={'secondary'} className={'w-50'} style={{position: 'relative', overflow: 'hidden'}} onClick={() => setState({play: !state.play, progress: 0})}>
      {
        state.play
          ?
          <>
            <div className={'progress md animate'}>
              <div style={{width: state.progress + '%'}}></div>
            </div>
            <ReactAudioPlayer ref={audioRef} src={require(`./assets/audio/${sex}_${type}.mp3`)} autoPlay
                              onPlay={handlePlay}
                              onEnded={() => setState({play: false, progress: 0})}
            />
            <em className={'stop me-3'}></em>
          </>
          : <IconVoice className={'me-3'} fill={'currentColor'}/>
      }
      {t(type)}
    </Button>
  );
}