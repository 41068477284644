import React from 'react';

export default function Stars({className}) {
  return (
    <div className={'stars' + (className ? ' ' + className : '')}>
      {Array.from(Array(5), (e, i) => {
        return (
          <img key={i} src={require('../assets/img/icon/star.svg').default} alt="★" width={16} height={16}/>
        );
      })}
    </div>
  )
}
