import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  PaymentRequestButtonElement
} from '@stripe/react-stripe-js';

import mixpanel from 'mixpanel-browser';
import {dataFetch, gtagEvent} from "../utils/lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {sha256} from "js-sha256";
import {GoogleEventEnum} from "../_business/GoogleEventEnum";


function CheckoutForm({data, onResult, nextUrl}) {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!stripe)
      return;

    const pr = stripe.paymentRequest({
      // country: data.country,
      country: 'US',
      currency: data.currency.toLowerCase(),
      total: {
        label: data.name,
        amount: Math.round(+data.fullPrice * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
      // requestPayerPhone: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      console.log('result', result)

      window.result = result;
      onResult(result);

      if (result)
        setPaymentRequest(pr);
    });

    pr.on('paymentmethod', (e) => {
      const paymentMethod = e.paymentMethod

      // console.log('paymentMethod', paymentMethod)

      if (paymentMethod?.id) {
        let type = null;

        if (window.result?.applePay)
          type = 'Apple Pay';
        else if (window.result?.googlePay)
          type = 'Google Pay';

        mixpanel.track('start checkout', {type, id: paymentMethod.id});

        if (window.ttq) {
          window.ttq.track('InitiateCheckout', {
            "value": data.fullPrice, // number. Value of the order or items sold. Example: 100.
            "currency": data.currency, // string. The 4217 currency code. Example: "USD".
            "contents": [
              {
                "content_id": data.id, // string. ID of the product. Example: "1077218".
                "content_name": data.name // string. The name of the page or product. Example: "shirt".
              }
            ]
          }, {
            event_id: sha256('InitiateCheckout_' + window.session_uid)
          });
        }

        gtagEvent(GoogleEventEnum.checkout);

        // call your server and pass it the payment method
        // for Server side Stripe-API handling of payment

        dataFetch(
          process.env.REACT_APP_API_URI + 'web-payments',
          {...data, paymentId: paymentMethod.id},
          async (response) => {
            e.complete('success'); // Completes the transaction and closes Google/Apple pay
            window.localStorage.setItem('transaction_id', response.transaction_id);

            mixpanel.track('payment', {type, id: paymentMethod.id});

            if (window.ttq) {
              window.ttq.track('CompletePayment', {
                "value": data.fullPrice, // number. Value of the order or items sold. Example: 100.
                "currency": data.currency, // string. The 4217 currency code. Example: "USD".
                "contents": [
                  {
                    "content_id": data.id, // string. ID of the product. Example: "1077218".
                    "content_name": data.name // string. The name of the page or product. Example: "shirt".
                  }
                ]
              }, {
                event_id: sha256('CompletePayment_' + window.session_uid)
              });
            }

            gtagEvent(GoogleEventEnum.purchase, {'value': data.fullPrice, 'currency': data.currency});

            navigate(nextUrl);

            // const customer = await stripe.customers.create({
            //   payment_method: paymentMethod.id,
            //   email: paymentMethod.billing_details.email,
            //   invoice_settings: {
            //     default_payment_method: paymentMethod.id,
            //   },
            // });
            //
            // const subscription = await stripe.subscriptions.create({
            //   customer: customer.id,
            //   items: [{ plan: "plan_HereIsYourPlanID" }], // <--
            //   trial_period_days: 7,
            //   expand: ["latest_invoice.payment_intent"],
            // });
          }
        );
      } else {
        mixpanel.track('stripe single payment error', {type: 'fail'});
        e.complete('fail');
      }
    });
  }, [stripe, data]);

  const options = {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: 'buy',
        // One of 'default', 'book', 'buy', or 'donate'
        // Defaults to 'default'

        theme: 'dark',
        // One of 'dark', 'light', or 'light-outline'
        // Defaults to 'dark'

        height: '3rem',
        // Defaults to '40px'. The width is always '100%'.
      },
    }
  }

  if (paymentRequest)
    return <PaymentRequestButtonElement key={Math.random()} className={'w-100'} options={options}/>;

  return (
    <p className={'text-center w-100'}>
      <span className={'spinner'}></span>
      <br />
    </p>
  );
}

export default function PaymentStripeWallet({data, onResult, nextUrl}) {
  const {i18n} = useTranslation();

  // https://stripe.com/docs/stripe-js/elements/payment-request-button?client=react
  // https://stackoverflow.com/questions/60865022/stripe-payment-request-button-with-subscriptions

  if (!window.stripePromise)
    window.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Elements stripe={window.stripePromise} options={{locale: i18n.resolvedLanguage}}>
      <CheckoutForm data={data} onResult={onResult} nextUrl={nextUrl}/>
    </Elements>
  );
}
