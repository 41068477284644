import React from 'react';
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Checkbox from "./parts/Checkbox";
import {useNavigate} from "react-router-dom";
import {
  getItem,
  getResults,
  getSurveyData,
  setStep,
  upTimer
} from "./utils/lib";
import Logo from "./parts/Logo";
import ChoiceList from "./parts/ChoiceList";
import {SurveyEnum} from "./_business/SurveyEnum";
import {useTranslation} from "react-i18next";
import Button from "./parts/Button";

export default function Review({step, toAdd, nextUrl, preload}) {
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const navigate = useNavigate();
  const headerRef = React.useRef();

  const programList = t('review.programList', {returnObjects: true});

  // const A22 = i18n.resolvedLanguage === 'en' ? getExperiment('A22') : {value: 'A'};
  // const A25 = getExperiment('A25');

  for (const item of programList)
    item.value = 0;

  const [state, setState] = React.useState({
    timer: null,
    intervals: [3000, 4000, 5000, 4000],
    programList,
    resultList: [],
    // summarize: [],
  });

  const pause = 49;

  let isNext = true;

  let header; // = <>We are crafting your <em>learning experience...</em></>;

  for (const item of state.programList) {
    if (item.value < 100) {
      header = item.loads[item.value < 50 ? 0 : 1];
      isNext = false;
      break;
    }
  }

  React.useEffect(() => {
    if (isNext)
      goToNext();
  }, [isNext]);

  React.useEffect(() => {
    console.time("loading");

    const resultList = getItem('survey_results');

    if (resultList.length < surveyList.length) {
      navigate('/survey', {replace: true});
      return;
    }

    // if (i18n.resolvedLanguage === 'en') {
    if (1) {
      try {
        console.log('try fetch summarize...');
        console.time('fetch');

        fetch(process.env.REACT_APP_API_URI + 'web-sessions/summarize', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            lang: i18n.resolvedLanguage,
            version: process.env.REACT_APP_VERSION,
            session_uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
            age: window.localStorage.getItem('age'),
            data: getResults(surveyList, resultList),
          })
        })
          .then(response => {
            console.timeEnd('fetch');
            console.log(response);

            return response.json();
          })
          .then(({messages}) => {
            console.log('messages', messages)

            if (messages) {
              console.warn('valid');
              window.summarize = messages;
              // state.summarize = messages;
              // setState({...state});
            } else
              console.warn('not valid');
          });
      } catch (err) {
        console.error(err);
      }

      // dataFetch(
      //   process.env.REACT_APP_API_URI + 'web-sessions/summarize',
      //   data,
      //   ({messages}) => {
      //     if (messages) {
      //       window.summarize = messages;
      //       // state.summarize = messages;
      //       // setState({...state});
      //     }
      //   }
      // );
    }

    startInterval();
    setStep(step, toAdd);

    return () => clearInterval(state.timer);
  }, []);


  const startInterval = () => {
    if (state.timer || !state.intervals.length)
      clearInterval(state.timer);

    const ind = Math.floor(Math.random() * state.intervals.length);
    state.timeout = state.intervals[ind];
    state.interval = Math.round(state.timeout / 100);
    state.time = state.interval;
    state.pauses = Math.floor(Math.random() * 3);
    delete state.pause;
    // console.log('pauses', state.pauses)

    state.intervals.splice(ind, 1);
    startTimer();
  };

  const startTimer = () => {
    // clearInterval(state.timer);

    state.timer = setInterval(() => {
      let isNext = true, isEnd = false;

      for (const item of state.programList) {
        if (item.value < 100) {
          item.value++;
          isEnd = item.value === 100;

          if (item.value !== pause || !item.title)
            isNext = false;

          if ([50, 100].includes(item.value)) {
            headerRef.current?.classList.add('fade-in');
            setTimeout(() => {
              headerRef.current?.classList.remove('fade-in');
            }, 1000);
          } else {
            if (!state.pause && state.pauses > 0) {
              state.pause = item.value + Math.floor(Math.random() * (99 - item.value));
              // console.log('pause', state.pause)
              state.pauses--;
            }

            if (!isNext && state.pause && item.value >= state.pause) {
              // console.log('paused')
              clearInterval(state.timer);
              delete state.pause;
              state.time = state.interval * (Math.floor(Math.random() * 2) ? 0.5 : 2);
              setTimeout(startTimer, 250 + Math.floor(Math.random() * 750));
            }
          }

          break;
        }
      }

      if (isNext)
        clearInterval(state.timer);
      else if (isEnd)
        startInterval();

      setState({...state});
    }, state.time);
  };

  function handleChange(index, checkedList) {
    // console.log('handleChange', index, checkedList)

    state.resultList[index] = checkedList;
    // setState({...state});

    window.localStorage.setItem(
      'program_results',
      JSON.stringify(state.resultList)
    );

    upTimer(() => {
      startTimer();
    });
  }

  const handleFillResults = () => {
    for (const [i, item] of programList.entries()) {
      if (!state.resultList[i])
        state.resultList[i] = item.title ? [0] : null;
    }

    window.localStorage.setItem(
      'program_results',
      JSON.stringify(state.resultList)
    );

    goToNext();
  };

  const goToNext = () => {
    console.timeEnd('loading')

    // if (i18n.resolvedLanguage === 'en') {
      // if (state.summarize?.length) {
      if (window.summarize?.length) {
        // const A24 = getExperiment('A24');

        // if (A24.value === 'B') {
          nextUrl = '/summarize';

          window.sessionStorage.setItem(
            'summarize',
            // JSON.stringify(state.summarize)
            JSON.stringify(window.summarize)
          );
        // }
      } else {
        // если юзер пришел на экран быстрее чем ответ или в ответе ошибка - скипаем
        // если чат не показываем по тех причинам - идем по а варианту
        // (т е чтобы результатах теста мы видели именно когда экран был показан)

        // setExperiment({name: 'A24', value: 'A'});
      }
    // }

    navigate(nextUrl);
  };

  return (
    <div className={'wrap fixed lg'}>
      <Top>
        {
          process.env.REACT_APP_DEBUG &&
          <Button variant={'gold'} size={'sm'} onClick={handleFillResults} style={{position: 'fixed', top: 5, right: 5, width: 'auto'}}>fill</Button>
        }
        <Logo/>

        <div>
          <h1 style={{display: 'inline'}} ref={headerRef} dangerouslySetInnerHTML={{__html: header}}>
            {
              // isNext
              //   ? <>Communicate better<br/><em>with Vocal Image</em></>
              //   : header
            }
          </h1> <span className="dots h1"><span>.</span><span>.</span><span>.</span></span>
        </div>

        {
          state.programList.map((item, index) => {
            if (!item.value)
              return false;

            return (
              <div key={index} className={'progress-wrap' + (!item.value ? ' disabled' : '')}>
                <div>
                  <label>{item.name}</label>
                  {
                    item.value > 0 && item.value < 100 &&
                    <em>{item.value}%</em>
                  }
                  {
                    item.value >= 100 &&
                    <Checkbox checked={true} disabled={true} className={'success'} />
                  }
                </div>
                <div className={'progress'}><div style={{width: item.value + '%'}}></div></div>
                {
                  item.value === pause && item.title &&
                  <>
                    <div className={'line'} />
                    <div className={'block items'}>
                      <div style={{flexDirection: 'column', gap: '0.25rem'}}>
                        <em>{t('review.popNote')}</em>
                        <h3>{item.title}</h3>
                      </div>
                      <ChoiceList survey={{type: SurveyEnum.boolean, list: [{title: t('no')}, {title: t('yes')}]}} onChange={handleChange.bind(this, index)}/>
                    </div>
                  </>
                }
              </div>
            );
          })
        }
      </Top>

      <Bottom footer={isNext} preload={preload}>
        {
          // isNext
          //   ? <LinkButton to={nextUrl}>Continue</LinkButton>
          //   :
              <div className={'paragraph'}>
                <div className={'stars'}>
                  {Array.from(Array(5), (e, i) => {
                    return (
                      <img key={i} src={require('./assets/img/icon/star.svg').default} alt="★"/>
                    );
                  })}
                </div>
                <h3 className={'text-center'} dangerouslySetInnerHTML={{__html: t('review.people')}}/>
                <p className={'note'}>{t('review.downloads')}</p>
                <span className={'tag tag-btn-sm'}>{t('review.since')}</span>
              </div>
        }
      </Bottom>
    </div>
  )
}
