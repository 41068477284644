import React from 'react';
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import {dataFetch, getItem, getResults, getSurveyData, gtagEvent, setSession, setStep} from "./utils/lib";
import {sha256} from 'js-sha256';

import Logo from "./parts/Logo";
import {Steps} from "./Survey";

import {useTranslation, Trans} from "react-i18next";
import RiveLoad from "./parts/RiveLoad";
import Cookies from "js-cookie";
import {GoogleEventEnum} from "./_business/GoogleEventEnum";


export default function SignIn ({step, toAdd, nextUrl, preload}) {
  const {t, i18n} = useTranslation();
  // const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true, lng: 'en'}), i18n.resolvedLanguage);
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);
  const resultList = getItem('survey_results');

  const goalList = t('signIn.goals', {returnObjects: true});

  const
      s1 = surveyList.findIndex(({id}) => id === 'target1'),
      g1 =
        window.landing.includes('masculinity')
          ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
          : +resultList?.[s1]?.[0] || 0;

  const goal = goalList?.[g1] || goalList[0];

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [validated, setValidated] = React.useState(false);

  const inputRef = React.useRef();
  const buttonRef = React.useRef();

  // const A5 = getExperiment('A5');
  // const A7 = getExperiment('A7');
  // const A8 = window.landing === 'masculinity' ? {value: 'A'} : getExperiment('A8');
  const A8 = {value: 'B'};

  React.useEffect(() => {
    if (resultList.length < surveyList.length) {
      navigate('/survey', {replace: true});
      return;
    }

    if (!window.localStorage.getItem('program_results')) {
      navigate('/loading', {replace: true});
      return;
    }

    setStep(step, toAdd);

    // setTimeout(() => {
    //   inputRef.current.style.borderColor = '#fff';
    // }, 2000);
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleSubmit(e) {
    e.preventDefault();

    const email = document.getElementById('email').value;

    if (!validateEmail(email)) {
      alert(t('signIn.checkEmail'));
      return;
    }

    const getFbp = () => {
        let result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
          return null;
        }
        return result[1];
    };

    const getFpc = () => {
        let result = /_fbc=(fb\.1\.\d+\.\S+)/.exec(window.document.cookie);
        if (!(result && result[1])) {
          return null;
        }
        return result[1].replace(';', '');
    };

    const fbp = getFbp();
    const fbc = getFpc();

    setIsLoading(true);

    const data = {
      lang: i18n.resolvedLanguage,
      age: window.localStorage.getItem('age'),
      email,
      fbp,
      fbc,
      positionList: resultList,
      resultList: getResults(surveyList, resultList),
      positionProgramList: getItem('program_results'),
      session_uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
      ttclid: Cookies.get('ttclid'),
      ttp: Cookies.get('_ttp'),
    };

    if (!data.session_uid) {
      data.session_uid = 'b-' + (new Date()).getTime() + Math.floor(Math.random() * 1000);
      window.session_uid = data.session_uid;
      setSession({lang: i18n.resolvedLanguage});
    }

    dataFetch(
      process.env.REACT_APP_API_URI + 'web-members',
      data,
      (response) => {
        // const response = {"uid":"02d6b0ff-6dae-44dc-9400-68673ef7dab8"};
        mixpanel.identify(response.uid);
        mixpanel.people.set({email: data.email});
        window.localStorage.setItem('uid', response.uid);
        window.localStorage.setItem('email', data.email);

        gtagEvent(GoogleEventEnum.register);

        if (window.ttq) {
          window.ttq.identify({
            "email": sha256(data.email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
            // "phone_number": "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
            "external_id": sha256(window.session_uid) // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
          });
          // window.ttq.track('CompleteRegistration', {
          //   "contents": [
          //     {
          //       "content_id": "email" // string. ID of the product. Example: "1077218".
          //     }
          //   ]
          // }, {
          //   event_id: sha256('CompleteRegistration_' + window.session_uid)
          // });
          if (window.ttq) {
            window.ttq.track('AddToCart', {
              "contents": [
                {
                  "content_id": "subscription" // string. ID of the product. Example: "1077218".
                }
              ]
            }, {
              event_id: sha256('AddToCart_' + window.session_uid)
            });
          }
        }

        navigate(nextUrl);
      }
    );
  }

  // const mapping = {
  //   type0: [
  //     'Improving communication skills',
  //     'Enriching relationships',
  //     'Boosting career',
  //     'Enhancing singing skills',
  //     'Masculinizing voice',
  //     'Feminizing voice',
  //     'Reducing accent',
  //     'Healing voice',
  //   ],
  //   type1: ['extrovert', 'introvert', 'ambivert'],
  //   type2: ['talkers', 'listeners'],
  //   type3: [
  //     'Express your thoughts clearly if you don’t afraid of speaking in public',
  //     'Express your thoughts clearly if you do afraid of speaking in public'
  //   ],
  //   type4: [
  //     'For people who don’t have free time',
  //     'For people who have time only for a micro-session',
  //     'For people who can give a chance for a few sessions',
  //     'For people who ready for regular commitment'
  //   ]
  // };

  return (
    <div className={'wrap fixed'}>
      <Top>
        <div className={'row'} style={{height: '40px', justifyContent: 'center'}}>
          <Logo/>
        </div>

        {
            ['A', 'C', 'D'].includes(A8.value) &&
            <Steps index={surveyList.length - 1}/>
        }

        {
            ['D', 'E'].includes(A8.value) && i18n.resolvedLanguage === 'en'
              ?
                <>
                  <h1>{goal}</h1>
                  <p className={'small-sm'} style={{color: 'rgba(179, 185, 205, 0.6)'}}>{t('signIn.notice-A8')}</p>
                </>
                :
                <>
                  <h1>{t('signIn.h1')}</h1>

                  {/*<div className={'block items text-start'}>
                    <p className={'w-100'}><strong>Key points:</strong></p>
                    <div className={'paragraph'}>
                      {
                        [
                          mapping.type0[resultList[1]?.[0] || 0] || mapping.type0[0],
                          `Tailored for ${mapping.type1[resultList[6]?.[0]] || 0} ${mapping.type2[resultList[12]?.[0] || 0]}`,
                          mapping.type3[resultList[22]?.[0] || 0],
                          mapping.type4[resultList[31]?.[0] || 0],
                        ].map((item, i) =>
                          <p key={i} className={'notice small row fade-in-translate'} style={{gap: '0.75rem', animationDuration: 0.5 * (i + 1) + 's'}}>
                            <img src={require('./assets/img/icon/star2.svg').default} alt={'★'}/>
                            {item}
                          </p>
                        )
                      }
                    </div>
                  </div>*/}

                  <p className={'notice'} dangerouslySetInnerHTML={{__html: t('signIn.notice')}}/>
                </>
        }

        <form onSubmit={handleSubmit} className={'w-100'} style={{position: 'relative'}}>
          <input type={'submit'} className={'d-none'}/>

          {
            // A7.value === 'A' &&
            // A5.value === 'A' &&
            <div style={{position: 'absolute', right: -25, top: -42, width: 138, height: 93, pointerEvents: 'none'}}>
              <RiveLoad width={138} height={93} src={'email_cat.riv'} stateMachines="email_cat" image={require(`./assets/img/cat.svg`).default}/>
            </div>
          }
          <input ref={inputRef} id={'email'} type={'email'} placeholder={t('signIn.email')}
                 className={'form-control mb-4'} required
                 pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 onInput={(e) => setValidated(validateEmail(e.target.value))}/>

          <Button doRef={buttonRef} type={'submit'} className={!validated ? 'disabled' : ''} onClick={() => {
            if (!validated) inputRef.current.focus()
          }}>
          {t('signIn.btn')}
            {isLoading && <span className="spinner ml-2"></span>}
          </Button>
        </form>

        {
            ['D', 'E'].includes(A8.value) && i18n.resolvedLanguage === 'en'
              ?
                <p className={'note md'} style={{fontWeight: 400}} dangerouslySetInnerHTML={{__html: t('signIn.info1-A8')}}/>
                :
                <>
                  <p className={'note md'} style={{fontWeight: 400}}>{t('signIn.info1')}</p>
                  <p className={'note md'} style={{fontWeight: 400}}>{t('signIn.info2')}</p>
                </>
        }

        {
            ['C', 'E'].includes(A8.value) &&
            <div className={'paragraph'}>
              <img src={require('./assets/img/icon/cup.png')} alt="Amazon Web Service AI Сhallenge Winner" width={130} height={77}/>
            <p className={'gold'} style={{fontWeight: 700, fontSize: 18}}>Amazon Web Service<br />AI Сhallenge Winner</p>
          </div>
        }

        <p className={'note md'} style={{fontWeight: 400}}>
          <Trans i18nKey={'signIn.info3' + (['D', 'E'].includes(A8.value) && i18n.resolvedLanguage === 'en' ? '-A8' : '')} shouldUnescape={true}>
            Please be aware that your use of Vocal Image is subject to our
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'terms'} target={'_blank'}
               className={'link nowrap'}>Terms &amp; Conditions</a>,
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'privacy'} target={'_blank'}
               className={'link nowrap'}>Privacy Policy</a>, and
            <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'subscription'} target={'_blank'}
               className={'link nowrap'}>Subscription Policy</a>.
            These documents collectively outline the guidelines and policies governing your interaction with our
            platform.
          </Trans>
        </p>

        {/*        <Checkbox className={'md'} required
          label={
            <span className={'note lg'}>
              I agree to the Vocal Image&nbsp;
              <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'terms'} target={'_blank'} className={'link'}>Terms&Conditions</a> and&nbsp;
              <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'privacy'} target={'_blank'} className={'link'}>Privacy Policy</a>
            </span>
          }
        />*/}
      </Top>

      <Bottom preload={preload}/>
    </div>
  );
}
