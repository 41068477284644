import React from 'react';
import Stars from "./Stars";
import {useTranslation} from "react-i18next";

export default function Comments() {
  const {t} = useTranslation('common', {keyPrefix: 'paywall.info'});

  const list = t('comments', {returnObjects: true});

  return (
    <div className={'items text-start'}>
      {
        list.map((item, index) => {
          return (
            <div key={index} className={'item block'}>
              <Stars/>

              <p className={'notice md'}>{item.message}</p>

              <div className={'row sm'}>
                <img src={require('../assets/img/face/' + item.avatar)} alt="" width={24} height={24} className={'ava'} />
                <p className={'notice small'}>{item.contact}</p>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
