import React from 'react';
import RivePreload from "./RivePreload";
import {useTranslation} from "react-i18next";

export default function Bottom({footer = true, preload, children}) {
  const {t} = useTranslation();

  return (
    <div className={'bottom' + (children ? ' pt-0' : '')}>
      {children}

      {
        footer &&
        <footer>
          <p>{t('address')}</p>
          <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'terms'} target={'_blank'}>{t('terms')}</a>
          <i className={'circle'}></i>
          <a rel="noreferrer" href={process.env.REACT_APP_WEB_LINK + 'privacy'} target={'_blank'}>{t('privacy')}</a>
        </footer>
      }

      {
        preload &&
        <RivePreload src={preload}/>
      }
    </div>
  )
}
