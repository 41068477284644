import React from 'react';
import Top from "./parts/Top";

import LinkButton from "./parts/LinkButton";
import {useNavigate} from "react-router-dom";
import {getItem, setStep} from "./utils/lib";
import {Trans, useTranslation} from "react-i18next";
import Bottom from "./parts/Bottom";
import Button from "./parts/Button";
import mixpanel from 'mixpanel-browser';

export default function Book({step, nextUrl, preload}) {
  const {t} = useTranslation('common', {keyPrefix: 'book'});

  const navigate = useNavigate();

  const price = getItem('price', null, window.sessionStorage);
  // const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/');
      return;
    } else if (!price) {
      navigate(nextUrl);
      return;
    }

    setStep({
      ...step,
      product: window.localStorage.getItem('product') || 'noname'
    });

    window.sessionStorage.setItem('success', '1');
  }, [step]);

  const handleClick = () => {
    // setIsLoading(true);

    fetch(
      `${process.env.REACT_APP_API_URI}web-payments/book`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: price.id,
          uid: window.localStorage.getItem('uid'),
          // transaction_id: window.localStorage.getItem('transaction_id')
        })
      }
    )
      .then(r => {
        console.log('book checkout')
        mixpanel.track('book checkout', {
          product: window.localStorage.getItem('product') || 'noname'
        });
      });

    navigate(nextUrl);
  }

  if (!price)
    return;

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <h1 className={'h2'}>{t('h1')}</h1>
        <p className={'notice small'}>{t('notice')}</p>

        <div className={'small text-start'} style={{position: 'relative', top: -7}}>
          <div style={{position: 'absolute', right: 10, top: 20, bottom: 48, width: 146, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <Trans i18nKey="list" t={t}>
              <ul className={'pre-line mb-2'}>
                <li>20+ guided voice trainings</li>
                <li>Expert-level tips and advices</li>
                <li>Train for just 3 minutes per day</li>
              </ul>
            </Trans>

            <div className={'row sm'}>
              <span className={'tag tag-btn-sm'}>e-book</span>
              <span className={'tag tag-btn-sm'}>pdf</span>
            </div>
          </div>

          <img src={require('./assets/img/pic/book.png')} alt="" width={353} height={218} className={'w-100 mw-100'}/>
        </div>

        <p className={'notice small mb-2'} style={{marginTop: -20}}>{t('special')}</p>

        {/*<Plan strict={true} listInput={[price]}/>*/}

        <div className={'items w-100'}>
          <div className={'block-plan active'} style={{position: 'relative'}}>
            {
              price.promo &&
              <div className={'promo small'}><em>{price.promo}</em></div>
            }
            <div className={'item'}>
              <div className={'w-100 text-start'}>
                <strong>{price.name}</strong>
                <p>
                    <s className={'note lg'}>{price.currency} {price.full_price}</s>
                </p>
              </div>
              <div className={'nowrap'}>
                <sup>{price.currencySymbol}</sup>
                <big>{Math.trunc(price.price)}</big>
                <div>
                  <em>{Math.round(price.price % 1 * 100)}</em>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button onClick={handleClick}>
          {t('btn')}
          {/*{isLoading && <span className="spinner ml-2"></span>}*/}
        </Button>
        <LinkButton variant={''} to={nextUrl}>{t('skip')}</LinkButton>
      </Top>

      <Bottom preload={preload}/>
    </div>
  )
}
