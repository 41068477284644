import React from 'react';
import Bottom from "./parts/Bottom";

import Top from "./parts/Top";

import Logo from "./parts/Logo";
import {getItem, getSurveyData, setStep} from "./utils/lib";

import {Steps} from "./Survey";
import {useTranslation} from "react-i18next";
import LinkButton from "./parts/LinkButton";
import {useNavigate} from "react-router-dom";
import Markdown from "react-markdown";

export default function Summarize({step, toAdd, nextUrl, preload}) {

  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const list = getItem('summarize', [], window.sessionStorage);
  const resultList = getItem('survey_results');

  const sex =
    +resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
      ]?.[0] === 0 ? 'female' : 'male';

  const [state, setState] = React.useState({
    count: 1,
    typing: false,
  });

  const commentRef = React.useRef();
  const typingRef = React.useRef();


  React.useEffect(() => {
    // const A24 = getExperiment('A24');

    if (
      // A24.value === 'A' ||
      !list.length ||
      resultList.length < surveyList.length
    ) {
      navigate(nextUrl, {replace: true});
      return;
    }

    fetch(
      process.env.REACT_APP_API_URI + 'web-sessions/coach',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
        })
      }
    )
      .then(r => {});
      // .then(response => response.json())
      // .then(() => {});

    setStep(step, toAdd, true);
    typing();
  }, []);

  React.useEffect(() => {
    if (state.typing && typingRef.current)
      typingRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    else if (!state.typing && commentRef.current)
      commentRef.current.scrollIntoView({
        behavior: 'smooth'
      });
  }, [state.typing, typingRef.current, commentRef.current]);

  const typing = () => {
    if (list.length > state.count)
      setTimeout(() => {
        state.typing = true;
        setState({...state});

        addMessage();
      }, 2000);
  };

  const addMessage = () => {
    setTimeout(() => {
      state.count++;
      state.typing = false;
      setState({...state});

      typing();
    }, 7000);
  };

  return (
    <div className={'wrap fixed md'}>
      <Top>
        <Logo/>

        <Steps index={surveyList.length - 1}/>

        <h1 className={'h3'}>{t('summarize.title')}</h1>

        <div className={'w-100 h-100 scrollable'}>

          {
            list.map((txt, ind) => {
              if (ind >= state.count)
                return false;
// console.log(ind >= state.count - 1)
              return (
                <div key={ind} ref={ind >= state.count - 1 ? commentRef : null} className={'mb-2 animate-choice'}>
                  <Coach sex={sex}/>
                  <div className={'panel-comment'} style={{backgroundColor: '#0050FF', color: '#fff'}}>
                    <Markdown>{txt}</Markdown>
                  </div>
                </div>
              );
            })
          }

          {
            state.typing &&
            <div ref={typingRef} className={'fade-in'}>
              <Coach sex={sex}/>
              <div className={'panel-comment notice md'}>
                {t('summarize.typing')}
                <span className="dots"><span>.</span><span>.</span><span>.</span></span>
              </div>
            </div>
          }

        </div>
      </Top>

      <Bottom preload={preload}>
        {
          // state.count >= list.length &&
          <LinkButton className={'fade-in'} to={nextUrl}>{t('continue')}</LinkButton>
        }
      </Bottom>
    </div>
  )
}

function Coach({sex}) {
  const {t} = useTranslation('common', {keyPrefix: 'summarize'});

  return (
    <p className={'row sm mb-2'}>
      <img src={require(`./assets/img/face/ai-${sex}.png`)} alt="" width={32} height={32}/>
      <strong>{t('coach')}</strong>
    </p>
  );
}