import React from 'react';
import {loadStripe} from "@stripe/stripe-js";
import {Elements, useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import Button from "./Button";

import mixpanel from 'mixpanel-browser';
import {dataFetch, gtagEvent} from "../utils/lib";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {sha256} from "js-sha256";
import {GoogleEventEnum} from "../_business/GoogleEventEnum";


function CheckoutForm({data, nextUrl}) {
  const {t} = useTranslation('common', {keyPrefix: 'payment'});

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [message, setMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || disabled) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    mixpanel.track('start checkout', {type: 'Stripe'});

    if (window.ttq) {
      window.ttq.track('InitiateCheckout', {
        "value": data.fullPrice, // number. Value of the order or items sold. Example: 100.
        "currency": data.currency, // string. The 4217 currency code. Example: "USD".
        "contents": [
          {
            "content_id": data.id, // string. ID of the product. Example: "1077218".
            "content_name": data.name // string. The name of the page or product. Example: "shirt".
          }
        ]
      }, {
        event_id: sha256('InitiateCheckout_' + window.session_uid)
      });
    }

    gtagEvent(GoogleEventEnum.checkout);

    try {
      // create a payment method
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement),
        // billing_details: {
        //   name,
        //   email,
        // },
      });

      if (paymentMethod?.error) {
        console.error(paymentMethod.error);
        throw new Error(paymentMethod.error.message || 'Unexpected error');
      }

      // console.log(paymentMethod);
      // console.log({...data, paymentId: paymentMethod?.paymentMethod?.id});

      // call the backend to create subscription
      dataFetch(
        process.env.REACT_APP_API_URI + 'web-payments',
        {...data, paymentId: paymentMethod?.paymentMethod?.id},
        async (response) => {
          if (response.error) {
            setMessage(response.error);
            mixpanel.track('stripe payment card error', {message: response.error});
          } else if (!response.clientSecret) {
            setMessage('Unexpected error');
            mixpanel.track('stripe payment card error', {message: 'Unexpected error'});
          } else {
            const confirmPayment = await stripe?.confirmCardPayment(
              response.clientSecret, {
                return_url: document.location.origin + nextUrl
              }
            );

            if (confirmPayment?.error) {
              setMessage(confirmPayment.error.message);
              mixpanel.track('stripe payment card error', {message: confirmPayment.error.message});
            } else {
              if (window.ttq) {
                window.ttq.track('CompletePayment', {
                  "value": data.fullPrice, // number. Value of the order or items sold. Example: 100.
                  "currency": data.currency, // string. The 4217 currency code. Example: "USD".
                  "contents": [
                    {
                      "content_id": data.id, // string. ID of the product. Example: "1077218".
                      "content_name": data.name // string. The name of the page or product. Example: "shirt".
                    }
                  ]
                }, {
                  event_id: sha256('CompletePayment_' + window.session_uid)
                });
              }

              gtagEvent(GoogleEventEnum.purchase, {'value': data.fullPrice, 'currency': data.currency});

              window.localStorage.setItem('transaction_id', response.transaction_id);
              navigate(nextUrl);
            }
          }

          setIsLoading(false);
        }
      );
    } catch ({name, message}) {
      console.error(name, message);
      setMessage(message);
      mixpanel.track('stripe payment card error', {name, message});
      setIsLoading(false);
    }
  };

  function handleChange(data) {
    // console.log('change', data)
    setDisabled(!data.complete);
    setMessage(null);
  }

  const cardStyle = {
    style: {
      base: {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        color: "#eee",
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#B3B9CD"
        }
      },
      invalid: {
        color: "#CC1818",
        iconColor: "#CC1818"
      },
    }
  };

  if (message)
    cardStyle.style.base = {...cardStyle.style.base, ...cardStyle.style.invalid};

  return (
    <form id="payment-form" className={'w-100'} onSubmit={handleSubmit}>
      <CardElement key={data?.id} options={cardStyle} onChange={handleChange}/>

      <br />
      <Button disabled={disabled}>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M5.20327 2.20034C4.29022 2.9996 3.8335 3.96128 3.8335 5.08524V8.00195L3.50004 8.00186C3.22243 8.00186 2.98616 8.08698 2.79163 8.25717C2.59728 8.42738 2.5 8.63394 2.5 8.87669V14.1268C2.5 14.37 2.59728 14.5764 2.79163 14.7466C2.98616 14.9169 3.22243 15.002 3.50004 15.002H13.4999C13.7779 15.002 14.014 14.9169 14.2083 14.7466C14.4028 14.5764 14.4999 14.37 14.4999 14.1268V8.87672C14.4999 8.63378 14.4029 8.42738 14.2083 8.25717C14.014 8.08696 13.7777 8.00186 13.4999 8.00186H13.1666V5.08524C13.1666 3.96128 12.71 2.9996 11.7968 2.20034C10.8837 1.4014 9.78485 1.00195 8.49997 1.00195C7.2153 1.00195 6.11641 1.4014 5.20327 2.20034ZM7.71696 11.6436V13.3556C7.71696 13.7006 8.03667 13.9804 8.43097 13.9804H8.56887C8.96318 13.9804 9.28289 13.7006 9.28289 13.3556V11.6436C9.70204 11.4145 9.98184 11.0084 9.98184 10.5441C9.98184 9.82789 9.31826 9.24744 8.49991 9.24744C7.68156 9.24744 7.01798 9.82807 7.01798 10.5441C7.01801 11.0085 7.29784 11.4147 7.71696 11.6436ZM8.49986 2.75195C7.02529 2.75195 5.8299 3.79791 5.8299 5.08817V8.00195H11.1698V5.08817C11.1698 3.79791 9.97444 2.75195 8.49986 2.75195Z"/></svg>
        <em className={'ml-2'}>{t('continue')}</em>
        {/*Pay {data.currencySymbol}{data.fullPrice}*/}
        {isLoading && <span className="spinner ml-2"></span>}
      </Button>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default function PaymentStripeCard({data, nextUrl}) {
  const {i18n} = useTranslation();

  // https://stripe.com/docs/payments/accept-a-payment?ui=elements

  if (!window.stripePromise)
    window.stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Elements stripe={window.stripePromise} options={{locale: i18n.resolvedLanguage}}>
      <CheckoutForm data={data} nextUrl={nextUrl}/>
    </Elements>
  );
}
