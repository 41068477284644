import React from 'react';
// import Rive from "@rive-app/react-canvas";

export default function RivePreload({src}) {
  // return (
  //   <div style={{position: 'absolute', left: -5000, top: -5000, width: 412, height: 232}}>
  //     <Rive {...props}/>
  //   </div>
  // )

  return <link rel="prefetch" href={require('../assets/rive/' + src)} as="fetch" crossOrigin="anonymous"/>;
}
